export default {
  noData: '暂无数据',
  operation: '操作',
  delete: '删除',
  deleteSuccess: '删除成功！',
  details: '详情',
  update: '更新',
  updateSuccess: '更新成功',
  edit: '编辑',
  query: '查询',
  search: '搜索',
  reset: '重置',
  export: '导出',
  select: '选择',
  yl: '银络',
  newEdition: '新版',
  password: '密码',
  changePassword: '修改密码',
  oldPassword: '旧密码',
  newPassword: '新密码',
  confirmationNewPassword: '确认新密码',
  cancel: '取 消',
  save: '保 存',
  saveSuccess: '保存成功',
  cancelSuccess: '取消成功',
  submit: '提 交',
  confirm: '确定',
  noChange: '不变更上传',
  changeUpload: '变更上传',
  newadd: '新增',
  inputNewPassword: '请输入新密码',
  newdifferentOld: '新密码不能与旧密码相同',
  twicePasswordDifferent: '两次输入密码不一致,请确认并重新输入',
  passwordModifiedSuccess: '密码修改成功！',
  passwordDifferentAccount: '密码不能与登录账号相同',
  passwordRules:
    '密码由8-16位的大小写字母、数字以及特殊符号(仅支持下列特殊字符：~!@#$%^&*()_?)组成',
  spaceCapacity: '空间容量',
  signOut: '退出',
  more: '更多',
  tips: '提示',
  refresh: '刷新',
  backList: '返回列表',
  previous: '上一页',
  nextPage: '下一页',
  not: '无',
  to: '至',
  startDate: '开始日期',
  endDate: '结束日期',
  isCancel: '是否要取消',
  download: '下载',
  downloadTool: '下载工具',
  upload: '上 传',
  newSuccess: '新增成功！',
  editSuccess: '编辑成功！',
  addSuccess: '添加成功!',
  uploadFiles: '上传文件',
  systemError: '系统错误',
  index: '序号',
  ID: 'ID',
  fileDownloading: '源文件下载中',
  copySucceeded: '复制成功',
  copy: '复制',
  placeholder: '请选择',
  pleaseEnter: '请输入',
  succeeded: '操作成功',
  leastOne: '请至少选择一条数据',
  yes: '是',
  no: '否',
  closeOther: '关闭其他标签',
  closeRightOther: '关闭右侧标签',
  getDeviceLocalLogs: '获取设备本地日志',
  deviceOfflineTip: '设备离线，请检查设备是否安装应用以及网络连接。',
  deviceUploadTip: '设备数据上传中，请保持网络链接正常。',
  connecting: '连接中...',
  connectionSuccess: '连接成功',
  connectionFail: '连接失败，请检查网络环境',
  connectionClean: '连接已清除',
  step: '步骤',
  selectOrgan: '选择机构',
  selectMerchant: '选择服务商',
  customer: '客户',
  sort: '排序',
  spaceDetails: {
    spaceDetails: '空间容量详情',
    total: '总量',
    video: '视频',
    photo: '图片',
    installBag: '安装包',
    resourceName: '资源名称',
    resourceSize: '资源大小',
    resourceType: '资源类型'
  },
  loginPage: {
    login: '登录',
    loggingIn: '登录中...',
    accountPlaceholder: '请输入账号',
    passwordPlaceholder: '请输入密码',
    verificationCode: '请输入图形验证码',
    customerPlatform: '客户平台',
    sdyPlatform: '桑达云平台',
    longTerm: '长期',
    day: '天',
    year: '年',
    expired: '已过期',
    expiredMsg: '登录已过期，请重新登录',
    leave: '余'
  },
  adminHomePage: {
    deviceTotal: '累计设备',
    today: '今日',
    month: '本月',
    pos: 'POS系列',
    selfHelp: '自助系列',
    commercialDisplay: '商显系列',
    deviceModel: '设备型号占比',
    tower: '台',
    unit: '单位',
    serviceProvider: '服务商设备占比',
    devGroup: '设备分组占比',
    devOS: '设备OS版本',
    first: '第一',
    second: '第二',
    third: '第三',
    fourth: '第四',
    fifth: '第五',
    devFirmware: '设备固件版本占比',
    devRegion: '设备地域分布',
    devReception: '设备前台app',
    common: '共'
  },
  deviceList: {
    sn: 'SN号',
    store: '门店',
    affiliatedStore: '所属门店',
    onLine: '在线',
    peripheralName: '外设名称',
    peripheralType: '外设类型',
    bindingDevice: '绑定单个设备',
    bindingBatchDevice: '绑定批量设备',
    bindingBatchDeviceTemplate: '批量绑定设备模板',
    deviceType: '设备型号',
    deliveryCustomer: '出库客户',
    customerId: '客户ID',
    customer: '所属客户',
    deviceGroup: '设备分组',
    deviceStatus: '设备状态',
    firmwareVersion: '固件版本',
    onlineTimes: '累计上线次数',
    lastOnlineTime: '最后上线时间',
    createdTime: '创建时间',
    detailedAddress: '详细地址',
    address: '地址',
    conversation: '会话',
    retrieveTips:
      '请下载文件取回工具后，打开下载目录手动双击文件取回工具输入“地址”和“会话”后进行文件取回。会话有效期为15分钟，超时后请重新发起会话。',
    retrieveSdTips:
      '请下载文件取回工具后，按照步骤输入ip和密码进行连接，本次连接两分钟内有效，超时请重新发起文件取回。',
    remote: '远程协助',
    sedinittaRemote: '远程桌面',
    sedinittaRetrieve: '下载文件',
    closeWifi: '关闭wifi',
    getLocation: '获取定位',
    noLocation: '未获取到位置信息，请手动输入',
    alreadyCloseWifi: '已关闭wifi',
    closeGps: '关闭定位',
    openGps: '打开定位',
    closeVirtualKeys: '关闭虚拟按键',
    alreadyCloseVirtualKeys: '已关闭虚拟按键',
    openVirtualKeys: '开启虚拟按键',
    alreadyOpenVirtualKeys: '已开启虚拟按键',
    restart: '重启',
    restartSuccess: '重启成功',
    restoreFactorySettings: '恢复出厂设置',
    restoreFactorySettingsSuccess: '恢复出厂设置成功',
    setTimeZone: '设置时区',
    setSleep: '设置休眠',
    shutdown: '关机',
    transferFiles: '上传文件',
    filesTransfer: '文件传输到设备',
    screenBrightnessSetting: '屏幕亮度设置',
    fontSizeSettings: '字体大小设置',
    mainScreen: '主屏',
    secondaryScreen: '副屏',
    isNeedRemote: '是否要远程协助？',
    printer: '打印机',
    scanningDevice: '扫描设备',
    camera: '摄像头',
    electronicScales: '电子秤',
    wantTo: '是否要',
    wantTouninstall: '是否要卸载',
    deviceIsRemote: '设备正在远程',
    deviceIsOffline: '设备已离线',
    isDeleteDevice: '是否要删除所选设备？',
    isDeleteThisDevice: '是否要删除该设备？',
    basicDeviceDetails: '基础设备详情',
    logDownload: '日志下载',
    basicInfo: '基础信息',
    CPUStorageInfo: 'CPU及存储信息',
    appsInstalled: '已安装应用',
    gray: '灰度',
    versionNo: '版本号',
    packageName: '包名',
    selfStart: '自启动',
    selfStartSetting: '自启动设置',
    dominateScreen: '霸屏',
    dominateScreenSetting: '霸屏设置',
    peripheralInfo: '外设信息',
    peripheralConnectionType: '外设连接类型',
    peripheralStatus: '外设状态',
    firmwareVersion: '固件版本号',
    extranetIP: '外网IP地址',
    IPResolution: 'IP解析地址',
    shutdownTime: '关机时间',
    intranetIP: '内网IP地址',
    startupTime: '开机时间',
    cloudPlatformVersion: '云平台版本',
    systemVersion: '系统版本',
    CPUUtilization: 'CPU利用率',
    kernelNumber: '内核数',
    memory: '内存',
    disk: '磁盘',
    cpuTemperature: 'CPU温度',
    offLine: '离线',
    uninstallSuccee: '卸载成功',
    uninstallWaiting: '卸载中，卸载过程约5S',
    printerNormal: '打印机正常',
    printerException: '打印机未连接或未上电',
    printerMismatch: '打印机和调用库不匹配',
    printHeadOpen: '打印头打开',
    cutterNotReset: '切刀未复位',
    printHeadOverheating: '打印头过热',
    blackMarkError: '黑标错误',
    normal: '正常',
    abnormal: '异常',
    available: '可用',
    z: '总',
    logType: '日志类型',
    logSize: '日志大小',
    operationTime: '操作时间',
    androidVersion: '安卓版本号',
    otaUpgrade: 'ota升级',
    appIssued: '应用下发',
    other: '其他',
    installFail: '安装失败',
    noDownload: '暂无下载！',
    reset: '重置',
    timedRestart: '定时重启',
    timedSwitch: '定时开关机',
    retrieve: '文件取回',
    everyDay: '每天',
    weekly: '每周',
    everyMonth: '每月',
    customize: '自定义',
    executionMode: '执行模式',
    executionDate: '执行日期',
    executionTime: '执行时间',
    mon: '星期一',
    tue: '星期二',
    wed: '星期三',
    thu: '星期四',
    fri: '星期五',
    sat: '星期六',
    sun: '星期日',
    filter: '输入关键字进行过滤',
    task: '任务',
    taskName: '任务名称',
    isStatus: '指令下发状态',
    addSubgroup: '添加子分组',
    rename: '重命名',
    assignAdmin: '分配管理员',
    moveUp: '上移',
    moveDown: '下移',
    delGroup: '你确定要删除此分组',
    role: '角色',
    char30: '最多可设置30个字符',
    scheduledRestart: '定时重启',
    restartTime: '重启时间',
    move: '可以在同层级进行拖动',
    executionTimeTip: '请选择执行时间',
    selectNums: '一共选中 {nums} 件设备，',
    confirmTask: '确定删除任务，任务删除后将不再执行指令。',
    DisStatus0: '待下发',
    DisStatus1: '进行中',
    wirelessMacAddress: '无线mac地址',
    wiredMacAddress: '有线mac地址',
    bluetoothMac: '蓝牙mac地址'
  },
  deviceModal: {
    bindingDevice: '绑定设备',
    editDevice: '编辑设备',
    selectCustomer: '选择客户',
    selectGrouping: '选择分组',
    selectDeviceType: '选择设备类型',
    selectDeviceModel: '选择设备型号',
    pSelectDeviceModel: '请选择设备型号',
    pleaseEnterSNNumber: '请输入SN号',
    pleaseEnterDetailedAddress: '请输入详细地址',
    downloadTemplate: '下载模板',
    dropFilesHere: '将文件拖到此处',
    or: '或',
    clickUpload: '点击上传',
    importFailedData: '导入失败的数据',
    failureReason: '失败原因',
    pleaseUploadFile: '请上传文件',
    importSuccee: '导入完成，已成功导入',
    devices: '设备',
    strip: '条',
    dataImportFailed: '数据导入失败，请检查数据格式或确保设备已成功联网激活。',
    viewImportFailed: '查看导入失败的数据',
    selectFile: '选择文件',
    fileDescription: '单个文件大小不能超过300M,文件保存在"/Download/"目录下面',
    fileSize: '上传文件大小不能超过 300MB!',
    transferSuccess: '传输成功！',
    small: '小',
    default: '默认',
    large: '大',
    maximum: '最大',
    settingSuccess: '设置成功！',
    pleaseSelectThutdownTime: '请选择关机时间',
    shutdownImmediately: '立即关机',
    timedShutdown: '定时关机',
    undoShutdown: '撤销关机',
    permanent: '永久',
    once: '一次',
    sleepSettings: '休眠设置',
    never: '永不',
    second: '秒',
    minute: '分钟',
    pleaseSelectTimeZone: '请选择时区！',
    selectArea: '选择区域',
    repeatTip: '检测到文件中存在{nums}条设备历史已出库，是否变更已存在的设备出库信息？',
    exportRepeatData: '导出重复出库数据',
    exportRepeatDataName: '重复出库数据.xlsx',
    exportRepeatData2: '导出失败数据',
    exportRepeatDataName2: '设备出库失败数据.xlsx'
  },
  deviceType: {
    modelName: '设备型号名称',
    modelImg: '型号图片',
    deleteSelectedModel: '是否要删除所选型号?',
    deleteSelectedThisModel: '是否要删除该型号?',
    newDevice: '新增设备型号',
    editDevice: '编辑设备型号',
    productPange: '产品系列',
    selectProductPange: '请选择产品系列',
    deviceImg: '设备图片',
    pleaseUploadPicture: '请上传图片',
    pleaseEnterModel: '请输入型号',
    signFileName: '签名文件名',
    selectSignFileName: '请选择签名文件名',
    remark: '备注'
  },
  activeDevice: {
    activationTime: '激活时间',
    state: '状态',
    todayNew: '今日新增',
    weekNew: '本周新增',
    monthNew: '本月新增'
  },
  deviceRoom: {
    bindSingleEquipment: '设备出库',
    bindBatchEquipment: '设备批量出库',
    changeCustomers: '更换出库客户',
    deliveryTime: '出库时间',
    deleteIssueRecord: '是否要删除该出库记录？',
    newIssueEquipment: '新增出库设备',
    editIssueEquipment: '编辑出库设备',
    issueCustomer: '出库所属客户',
    operatingSystem: '操作系统',
    selectOperatingSystem: '选择操作系统',
    screen: '屏幕',
    selectScreen: '选择屏幕',
    selectPrinter: '选择打印机',
    selectScanningDevice: '选择扫描设备',
    selectCamera: '选择摄像头',
    selectElectronicScales: '选择电子秤',
    android: '安卓',
    windows: 'windows',
    singleScreen: '单屏',
    dualScreen: '双屏',
    batchAddIssueEquipment: '批量新增出库设备',
    batchAddIssueEquipmentTemplate: '批量新增出库设备模板',
    deliveryEquipment: '出库设备',
    noVer: '未获取到版本',
    noVersion: '未获取到固件版本号',
    noVersion2: '未获取到安卓版本',
    selectNone: '无',
    fail: ' 失败',
    changeNums: ' 已选择{nums}条设备出库记录，请选择变更后的出库客户'
  },
  peripheralList: {
    isEnable: '是否启用',
    deletePeripheral: '是否要删除该外设?',
    addPeripheral: '新增外设',
    editPeripheral: '编辑外设',
    selectPeripheralType: '请选择外设类型',
    newAttribute: '新增属性',
    enterPeripheralName: '请输入外设名称',
    enterUID: '请输入UID',
    enterPID: '请输入PID',
    enterAttributeName: '请输入属性名',
    attributeNameAlreadyExists: '该属性名已存在'
  },
  serviceManagement: {
    customerName: '客户名称',
    storeName: '门店名称',
    storeId: '门店编号',
    contacts: '联系人',
    phone: '联系方式',
    deadline: '截止时间',
    selectDeadline: '选择截止时间',
    termOfValidity: '有效期',
    deviceUpperLimit: '设备上限',
    enterDeviceUpperLimit: '请输入可以控制的设备上限',
    unlimited: '不限制',
    associatedCustomers: '关联客户',
    deleteSelectedCustomer: '是否要删除所选客户?',
    deleteThisCustomer: '是否要删除该客户?',
    deleteSelectedStore: '是否要删除所选门店?',
    deleteThisStore: '是否要删除该门店?',
    addAssociatedCustomers: '添加关联客户',
    enterCustomerName: '请输入客户名称',
    enterStoreName: '请输入门店名称',
    customerID: '客户ID',
    enterCustomerID: '请输入客户ID',
    enterContact: '请输入联系人',
    newCustomers: '新增客户',
    editCustomers: '编辑客户',
    newStore: '新增门店',
    editStore: '编辑门店',
    enterEmail: '请输入邮箱',
    email: '邮箱号',
    customerProfile: '客户简介',
    enterCustomerProfile: '请输入客户简介',
    storeProfile: '门店简介',
    enterStoreProfile: '请输入门店简介',
    freeSpace: '可用空间',
    enterFreeSpace: '请输入可用空间',
    correctContactInfo: '请输入正确的联系方式',
    changeCustomerName: '该客户已存在，请更改客户名称！',
    noteAssociatedCustomers: '注: 设置关联客户后，本客户可以添加绑定关联客户的设备',
    associatedTime: '关联时间',
    deleteAssociation: '删除关联',
    selectValidPeriod: '请选择有效期！',
    deviceMaxTips: '设备上限最多可输入',
    orgSelectTips: '客户已存在用户，无法修改机构'
  },
  serviceAccountManagement: {
    loginAccount: '登录账号',
    enterLoginAccount: '请输入登录账号',
    loginName: '登录姓名',
    role: '所属角色',
    selectRole: '请选择所属角色',
    accountStatus: '账号状态',
    collapseList: '收起列表',
    subAccountList: '子账号列表',
    admin: '管理员',
    operator: '操作员',
    customerAdmin: '客户管理员',
    customerOperator: '客户操作员',
    deleteSelectedAccount: '是否要删除所选账号？',
    deleteThisAccount: '是否要删除该账号？',
    newAccount: '新增账号',
    editAccount: '编辑账号',
    name: '姓名',
    enterName: '请输入姓名',
    password: '账号密码',
    confirmPassword: '确认密码',
    enterConfirmPassword: '请输入确认密码',
    pleaseSelectCustomer: '请选择客户',
    correctMobile: '请输入正确的手机号',
    correctMobileFormat: '请输入正确的手机号格式',
    enteremailPhone: '请输入邮箱或手机号',
    emailPhone: '邮箱/手机号'
  },
  serviceRoleManagement: {
    roleName: '角色名称',
    enterRoleName: '请输入角色名称',
    roleInfo: '角色简介',
    enterRoleInfo: '请输入角色简介',
    assignPermiss: '分配权限',
    deleteSelectedRole: '是否要删除所选角色?',
    deleteThisRole: '是否要删除该角色?',
    newRole: '新增角色',
    editRole: '编辑角色',
    allPermiss: '全部权限',
    modul: '模块',
    function: '功能'
  },
  sourceManagement: {
    resourceTitle: '资源标题',
    enterResourceTitle: '请输入资源标题',
    system: '所属系统',
    enable: '启用',
    stop: '停止',
    authorizationName: '授权名',
    enterAuthorizationName: '请输入授权名',
    authorizationURL: '授权URL',
    enterAuthorizationURL: '请输入授权URL，例如：/xx/xx',
    enterAuthorizationURL1: '请输入授权URL',
    enterAuthorizationURLFormat: '请输入正确的授权URL格式',
    addSubResources: '新增子资源',
    deleteTesourcesTips: '请确认所要删除的资源，如果所删资源含有子资源，所有子资源将无效！',
    son: '子',
    resources: '资源',
    editResources: '编辑资源',
    enterEditResources: '请输入资源名称',
    Icon: '图标',
    enterIcon: '请输入图标名称',
    sortValue: '排序值',
    enterSortValue: '请输入排序值',
    menu: '菜单',
    button: '按钮',
    userName: '用户名',
    userID: '用户ID',
    userIP: '用户IP',
    system: '系统',
    businessDescription: '业务描述',
    method: '方法',
    requestParams: '请求参数',
    response: '响应结果'
  },
  appList: {
    appName: '应用名称',
    enterAppName: '请输入应用名称',
    deliveryName: '投放名称',
    uploadApp: '上传应用',
    updateApp: '更新应用',
    applicableModel: '适用型号',
    selectApplicableModel: '选择适用型号',
    deleteApp: '是否要删除该应用?',
    uploadAppLoading: '应用上传中，请稍后...',
    appIcon: '应用图标',
    enterAppIcon: '请上传应用图标',
    explain: '说明',
    imgSize: '图片宽高为：120 X 120;',
    imgFormat: '图片支持格式：jpg、gif、jpeg、png等',
    appInfo: '应用介绍',
    enterAppInfo: '请输入应用介绍',
    appScreenshot: '应用截图',
    appScreenshotExplain: '请上传应用截图',
    versionDesc: '版本说明',
    enterVersionDesc: '请输入版本说明',
    maxLength: '最大长度不能大于50字符',
    uploadaApk: '请上传文件',
    uploadaSuccess: '上传成功',
    appDetails: '应用详情',
    downloadPackage: '下载安装包',
    appVersion: '应用版本',
    appSize: '应用大小',
    launchTime: '上线时间',
    historicalVersion: '历史版本',
    uploadFileWarnTip: '只能上传 .apk ,.exe ,.msi文件',
    automaticUpdate: '是否强制更新',
    model: '型号'
  },
  publishApp: {
    selectApp: '选择应用',
    selectDevice: '选择设备',
    complete: '完成',
    selectApps: '选择要发布的应用',
    selectListApps: '从应用列表中选择',
    forceUpdates: '强制更新',
    autoUpdates: '自动更新',
    updatesTips:
      '“选择为“是”时，设备接收软件完成后直接进行更新。为“否”时，软件未使用时静默更新，软件正在使用时，将提示用户进行确认是否更新”。',
    publishingMethod: '发布方式',
    selectPublishingMethod: '选择发布方式',
    selected: '已选',
    fullRelease: '全量发布',
    clearAll: '清除全部',
    noDevice: '暂无选择设备 ',
    noDeviceType: '暂无选择设备类型',
    common: '共',
    noGroupSelection: '暂无选择分组',
    appsPublish: '要发布的应用',
    preStep: '上一步',
    nextStep: '下一步',
    publishNow: '立即发布',
    release: '发布',
    byCustomer: '按客户',
    publishBySN: '按sn',
    publishDevice: '按设备型号发布',
    publishingMethodContent: '请选择发布方式的内容',
    fullReleaseTips: '全量发布设备大于3000台，请选择其他发布方式。',
    publishSuccess: '发布成功',
    enterDeviceModel: '请输入设备型号',
    whole: '全部',
    selectTo: '选择要',
    appOf: '的应用',
    uninstall: '卸载',
    SNTips: '请输入SN号码，多个SN号之间用逗号","隔开',
    add: '添加',
    addedSN: '已添加的SN',
    ge: '个',
    enterSNNumbe: '请输入需要添加的SN号',
    materialPublish: '要发布的素材'
  },
  operateList: {
    operationName: '操作名称',
    type: '类型',
    associatedDevicesNumber: '关联设备数量',
    associatedDevices: '关联设备',
    see: '查看',
    publishApp: '发布应用',
    uninstallApp: '卸载应用',
    deleteRecord: '确认删除该条记录?',
    mode: '方式',
    downloadDeviceList: '点击下载设备名单',
    downloadDeviceList1: '下载设备名单',
    viewByDevice: '按设备查看',
    viewByApp: '按应用查看',
    pushSuccee: '推送成功',
    downloaded: '已下载',
    alreadyInstalled: '已安装',
    noApp: '无此应用',
    uninstallComplete: '卸载完成',
    notPushed: '未推送',
    alreadyPushed: ' 已推送 ',
    installFail: '安装失败',
    pushed: '已推送',
    run: '已运行',
    uninstalled: '已卸载',
    pushFailed: '推送失败',
    pushStatus: '推送状态'
  },
  otaList: {
    projectName: '项目名称',
    enterProjectName: '请输入项目名称',
    addProject: '添加项目',
    editProject: '编辑项目',
    platform: '平台',
    selectPlatform: '选择平台',
    equipmentNumber: '设备数',
    versionManagement: '版本管理',
    deleteItem: '是否要删除该项目?',
    projectDesc: '项目描述',
    enterProjectDesc: '请输入项目描述',
    pleaseSelectPlatform: '请选择平台',
    pleaseSelectSystem: '请选择操作系统',
    selectProject: '选择项目',
    addVersion: '添加版本',
    editVersion: '编辑版本',
    versionName: '版本名称',
    enterVersionName: '请输入版本名称',
    fileSize: '文件大小',
    describe: '描述',
    deleteThisVersion: '是否要删除该版本?',
    enterVersion: '请输入版本号',
    OTAFiles: 'OTA文件',
    pleaseOTAFiles: '请上传OTA文件',
    versionLength: '最大长度不能大于40字符'
  },
  publishOta: {
    publishProject: '发布项目',
    configurePolicy: '配置策略',
    ItemsPublish: '要发布的项目',
    selectItemsPublish: '选择要发布的项目',
    upgradeMethod: '升级方式',
    sourceVersion: '源版本',
    selectSourceVersion: '请选择源版本',
    selectVersion: '请选择版本',
    enterSingleSN: '请输入单个SN号',
    pushContent: '推送内容',
    batchAddSN: '批量添加SN',
    batchAddSNNumber: '批量添加SN号',
    fileFormatTips: '请根据模板导入文件，支持Excel2007、2003',
    downloadTemplate: '下载导入模板',
    noCustomerSelected: '暂无选择客户',
    selectPublishingMethod: '请选择发布方式',
    addSingleSNNumber: '添加单个SN号',
    upgradeDetails: '升级详情',
    boundCustomer: '所属绑定客户'
  },
  statisticalManagement: {
    deviceType: '设备类型',
    yesterday: '昨天',
    last7days: '近7天',
    lastMonth: '近一个月',
    customTimeRange: '自定义时间范围',
    selectCustomTimeRange: '请选择自定义时间范围',
    byDeviceNumber: '按设备数量',
    byFirmwareVersion: '按固件版本',
    entryStartTime: '请填写开始时间',
    newActiveTrendChart: '新增活跃趋势图',
    date: '日期',
    newDevice: '新增设备',
    activeDevice: '活跃设备',
    versionDistributionMap: '版本分布图',
    version: '版本',
    ratio: '占比',
    appInstall: '应用安装',
    appInstallChart: '应用安装量趋势图',
    ranking: '排名',
    installNumber: '安装次数',
    byCPUTemperature: '按CPU温度',
    byMemoryPercentage: '按内存占比',
    byStoragePercentage: '按存储占比',
    memoryUsageRatio: '内存占用比',
    follow: '以下',
    above: '以上',
    storage: '存储',
    storageRatio: '存储占用比',
    deviceDistribution: '设备数量分布',
    systemDistribution: '系统分布图'
  },
  marketManagement: {
    materialName: '素材名称',
    uploadMaterial: '上传素材',
    editMaterial: '编辑素材',
    materialTips: '只能上传png/jpg/gif/mp4/rmvb/wmv文件，且单个文件大小不能超过1GB',
    materialTips1: '单个文件大小不能超过 1GB！',
    videoOnly: '视频只能单个上传！',
    basicInfo: '基本信息',
    name: '名称',
    type: '类型',
    format: '格式',
    measurement: '尺寸',
    size: '大小',
    programName: '节目名称',
    launchProgress: '投放中',
    released: '待投放',
    complete: '已结束',
    asOf: '截止到',
    pauseLaunch: '是否要暂停投放？',
    pauseLaunch2: '是否要继续投放？',
    enterName: '请输入名称',
    direction: '方向',
    horizontalScreen: '横屏',
    verticalScreen: '竖屏',
    rotationTime: '轮播时间',
    enterRotationTime: '请输入轮播时间',
    material: '素材',
    releaseTime: '投放时间',
    custom: '自定义',
    playFrequency: '播放频率',
    playTime: '播放时间',
    allDay: '全天',
    publishGroup: '按组发布',
    repeatEveryDay: '每天重复',
    Monday: '周一',
    Tuesday: '周二',
    Wednesday: '周三',
    Thursday: '周四',
    Friday: '周五',
    Saturday: '周六',
    Sunday: '周日',
    selectMaterial: '请选择素材'
  },
  customerHome: {
    warning7Days: '近7天设备预警',
    runnMemory: '运行内存',
    storageCapacity: '存储容量',
    second: '次',
    installAppStatistics: '安装app统计',
    devicesTotal: '设备总数',
    totalDevicesNumber: '总设备数',
    onlineEquipment: '在线设备',
    offlineEquipment: '离线设备',
    versionStatistics: '版本统计',
    firmwareCloudPlatform: '固件和云平台',
    firmwareVersionStatistics: '固件版本统计',
    cloudPlatformVersionStatistics: '云平台版本统计',
    batchPublish: '批量发布',
    installSpecifiedApp: '是否安装指定应用',
    printerShutdown: '打印机关机',
    enterStore: '请输入门店',
    moveGroup: '移动分组',
    allUsers: '全部用户可用',
    specifyUser: '指定用户可用',
    userList: '用户列表',
    selectedUsers: '已选用户',
    enterAppVersion: '请输入应用版本号',
    cancelAppStartup: '选择要取消自启动的应用',
    cancelStartMode: '取消自启动方式',
    selectCancelStartMode: '请选择取消自启动方式',
    allCancelStartup: '全量取消自启动 ',
    cancelApp: '要取消自启动的应用',
    cancelAutostartNow: '立即取消自启动',
    cancelStartup: '取消自启动',
    byGroup: '按组',
    bySN: '按sn',
    byDeviceModel: '按设备型号',
    full: '全量',
    cancelStartupContent: '请选择取消自启动方式的内容',
    fullCancelTips: '全量取消自启动设备大于3000台，请选择其他取消自启动方式。',
    cancelStartupSuccess: '取消自启动成功',
    enterGroupName: '请输入分组名称',
    pleaseSelectGroup: '请选择分组',
    dominateScreenApp: '霸屏应用',
    unloadMode: '卸载方式',
    selectUnloadMode: '请选择卸载方式',
    fullUnload: '全量卸载',
    appsToUninstall: '要卸载的应用',
    uninstallNow: '立即卸载',
    uninstallByGroup: '按组卸载',
    uninstallBySN: '按sn卸载',
    uninstallByDeviceModel: '按设备型号卸载',
    selectUninstallApp: '选择要卸载的应用',
    selectUninstallContent: '请选择卸载方式的内容',
    fullUnloadTips: '全量卸载设备大于3000台，请选择其他卸载方式。'
  },
  groupManagement: {
    groupName: '分组名称',
    groupInfo: '分组简介',
    devicesNumber: '设备数量',
    manageDevices: '管理设备',
    deleteSelectedGroup: '是否要删除所选分组?',
    deleteThisGroup: '是否要删除该分组?',
    addGroup: '新增分组',
    editGroup: '编辑分组',
    addDeviceToGroup: '添加设备至分组',
    singleAdd: '单个添加',
    batchAdd: '批量添加',
    addMethod: '添加方式',
    batchAddGroupTemplate: '批量新增设备至分组模板',
    addGroupTips: '注意: 请严格按照模板填写，否则可能新增失败',
    modifyGroup: '修改分组',
    modifySuccess: '修改成功！',
    curDeviceNumber: '当前设备数量',
    removeDevice: '是否要移出该设备?',
    removeSuccess: '移出成功!',
    endLaunch: '是否要结束投放?',
    flexibleMode: '灵活模式',
    flexibleModeStandby: '灵活模式下待机',
    autoRestore: '后自动恢复节目投放',
    autoRestoreTips: '注：灵活模式下点击屏幕即可退出节目投放',
    selectDeliveryMethod: '请选择投放方式',
    fullRelease: '全量投放',
    launchNow: '立即投放',
    launchByGroup: '按组投放',
    launchBySN: '按sn投放',
    launchByModel: '按设备型号投放',
    launch: '投放',
    selectLaunchTime: '请选择投放时间',
    selectPlayTime: '请选择播放时间',
    selectLaunchContent: '请选择投放方式的内容',
    fullReleaseTips: '全量投放设备大于3000台，请选择其他投放方式。',
    launchSuccess: '投放成功！',
    order: '顺序',
    selectUser: '选择用户',
    time: '时间'
  },
  customerStatistical: {
    InstallStatistics: '安装统计',
    InstallTrends: '安装趋势',
    appInstallChart: '应用安装量统计图',
    reissue: '补发',
    export: '导出',
    InstallFailed: '安装失败',
    InstallSuccess: '安装成功',
    isReissue: '是否要确认补发?',
    reissueSuccess: '补发成功！',
    install: '安装',
    active: '活跃',
    warningType: '预警类型',
    warningConfig: '预警配置',
    warningTime: '预警时间',
    subScreenWarning: '副屏预警',
    warningContent: '预警内容',
    PeripheralExceptionWarning: '外设异常预警',
    PeripheralException: '外设异常',
    PeripheralExceptionTips: '当开关打开时，监测外设运行情况',
    CPUHeatWarning: 'CPU温度预警',
    CPUHeatTips: '当温度超过设置温度则报警',
    runMemoryWarning: '运行内存预警',
    occupiedMemoryTips: '当占用内存大于设置比例则报警',
    storageWarning: '存储容量预警',
    setProportionWarning: '当占用存储容量大于设置比例则报警',

    electronicScalesRemind: '电子秤定期提醒设置',
    electronicScalesReminderFrequency: '提醒频率',
    electronicScalesReminderFixedFrequency: '固定提醒频率',
    electronicScalesReminderDesignateFrequency: '指定时间提醒',
    electronicScalesReminderFrequencyUnit: '小时',
    electronicScalesReminderTime: '提醒时间',
    electronicScalesReminderDevice: '提示设备',
    electronicScalesReminderDeviceAll: '全部设备',
    electronicScalesReminderDeviceDesignate: '指定设备',
    peripheralSelfTest: '外设自检设置',
    peripheralSelfTestInfo: '称重最大偏差',
    peripheralSelfTestTips:
      '可填入大于0的数值，单位为克，当电子秤称重正负偏差值超过录入的最大偏差值时，电子秤自检结果将标记为异常',
    oneDeviceAtleast: '请选择设备',
    timeInput: '请输入提醒时间',
    timeRepeats: '提醒时间存在重复',
    timeFrequency: '请选择提醒频率',

    use: '使用',
    cellPhone: '手机号',
    enterCellPhone: '请输入手机号',
    savePicture: '保存为图片',
    warningType1: 'CPU温度过高，温度超过{nums}℃',
    warningType2: '运行内存剩余不多，使用率超过{nums}%',
    warningType3: '存储空间剩余不多，使用率超过{nums}%',
    warningType4: '打印机异常',
    warningType5: '副屏未正常工作',
    warningType6: '扫描设备异常',
    warningType7: '摄像机异常',
    warningType8: '电子秤异常',
    warningTypeOther: '其他错误'
  },
  electronicScalesReminder: {
    sn: 'SN号',
    group: '设备分组',
    store: '门店',
    remindTime: '提醒时间',
    executeTime: '执行时间',
    startTime: '开始时间',
    endTime: '结束时间',
    deviceModel: '设备型号',
    unexecuted: '未执行'
  },
  deviceSelfTest: {
    sn: 'SN号',
    selfTestResult: '自检结果',
    finalSelfTestTime: '最后自检时间',
    model: '设备型号',
    group: '设备分组',
    store: '门店',
    startTime: '开始时间',
    endTime: '结束时间',
    operateTime: '操作时间',
    operateUserName: '操作人',
    operateContent: '操作内容',
    operateRecord: '修改记录',
    selfTestDetail: '自检详情',
    deviceName: '设备名称',
    deviceType: '设备类型',
    userSelfTestResult: '用户自检结果',
    connectTest: '连接测试',
    printTest: '打印测试',
    weighTestDeviation: '称重测试偏差',
    selfTestTime: '自检时间',
    setToNormal: '设置为正常',
    operationRecords: '操作记录'
  },
  organManagement: {
    parentOrgan: '上级机构',
    organ: '所属机构',
    orgName: '机构名称',
    enterOrgName: '请输入机构名称',
    remark: '备注',
    enterRemark: '请输入备注',
    selectOrganTip: '请选择机构',
    associateMerchantNum: '关联服务商数量',
    associateMerchant: '关联服务商',
    merchantId: '服务商id',
    merchantName: '服务商名称',
    deleteThisOrgan: '是否要删除该机构？'
  },
  basicsData: {
    首页: '首页',
    设备管理: '设备管理',
    设备监控: '设备监控',
    设备列表: '设备列表',
    系统管理: '系统管理',
    客户管理: '客户管理',
    门店管理: '门店管理',
    设备型号管理: '设备型号管理',
    分组管理: '分组管理',
    应用管理: '应用管理',
    应用列表: '应用列表',
    版本管理: '版本管理',
    发布应用: '发布应用',
    账号管理: '账号管理',
    角色管理: '角色管理',
    用户管理: '用户管理',
    客户账号管理: '客户账号管理',
    客户角色管理: '客户角色管理',
    资源管理: '资源管理',
    操作日志: '操作日志',
    OTA升级黑名单: 'OTA升级黑名单',
    'OTA升级黑名单-新增': 'OTA升级黑名单-新增',
    'OTA升级黑名单-删除': 'OTA升级黑名单-删除',
    '设备列表-详情': '设备列表-详情',
    '设备列表-编辑': '设备列表-编辑',
    '设备列表-删除': '设备列表-删除',
    '设备列表-新增': '设备列表-新增',
    '设备型号管理-新增': '设备型号管理-新增',
    '设备型号管理-编辑': '设备型号管理-编辑',
    '设备型号管理-删除': '设备型号管理-删除',
    '客户管理-新增': '客户管理-新增',
    '客户管理-编辑': '客户管理-编辑',
    '客户管理-删除': '客户管理-删除',
    '门店管理-新增': '门店管理-新增',
    '门店管理-编辑': '门店管理-编辑',
    '门店管理-删除': '门店管理-删除',
    '客户账号管理-新增': '客户账号管理-新增',
    '客户账号管理-编辑': '客户账号管理-编辑',
    '客户账号管理-删除': '客户账号管理-删除',
    '客户账号管理-子账号列表': '客户账号管理-子账号列表',
    '客户账号管理-修改密码': '客户账号管理-修改密码',
    '客户角色管理-新增': '客户角色管理-新增',
    '客户角色管理-编辑': '客户角色管理-编辑',
    '客户角色管理-删除': '客户角色管理-删除',
    '客户角色管理-分配权限': '客户角色管理-分配权限',
    '资源管理-新增': '资源管理-新增',
    '资源管理-编辑': '资源管理-编辑',
    '资源管理-删除': '资源管理-删除',
    '资源管理-新增子资源': '资源管理-新增子资源',
    '设备列表-发布': '设备列表-发布',
    '分组管理-新增': '分组管理-新增',
    '分组管理-编辑': '分组管理-编辑',
    '分组管理-删除': '分组管理-删除',
    '分组管理-管理设备': '分组管理-管理设备',
    '应用列表-新增': '应用列表-新增',
    '应用列表-编辑': '应用列表-编辑',
    '应用列表-删除': '应用列表-删除',
    '应用列表-详情': '应用列表-详情',
    '版本管理-详情': '版本管理-详情',
    '版本管理-下载': '版本管理-下载',
    '版本管理-删除': '版本管理-删除',
    '账号管理-新增': '账号管理-新增',
    '账号管理-编辑': '账号管理-编辑',
    '账号管理-删除': '账号管理-删除',
    '账号管理-修改密码': '账号管理-修改密码',
    '用户管理-新增': '用户管理-新增',
    '用户管理-编辑': '用户管理-编辑',
    '用户管理-删除': '用户管理-删除',
    '用户管理-修改密码': '用户管理-修改密码',
    '角色管理-新增': '角色管理-新增',
    '角色管理-编辑': '角色管理-编辑',
    '角色管理-删除': '角色管理-删除',
    '角色管理-分配权限': '角色管理-分配权限',
    全部激活设备: '全部激活设备',
    卸载应用: '卸载应用',
    处理进度: '处理进度',
    '处理进度-详情': '处理进度-详情',
    基础预警管理: '基础预警管理',
    '基础预警管理-配置': '基础预警管理-配置',
    mac地址预警管理: 'mac地址预警管理',
    电子秤设置: '电子秤设置',
    '设备列表-远程协助': '设备列表-远程协助',
    '设备列表-远程桌面': '设备列表-远程桌面',
    '设备列表-下载文件': '设备列表-下载文件',
    设备出库: '设备出库',
    '设备出库-新增': '设备出库-新增',
    '设备出库-编辑': '设备出库-编辑',
    云平台升级: '云平台升级',
    统计分析: '统计分析',
    设备统计: '设备统计',
    应用统计: '应用统计',
    电子秤提醒记录: '电子秤提醒记录',
    外设自检查询: '外设自检查询',
    系统OS统计: '系统OS统计',
    OTA升级: 'OTA升级',
    项目列表: '项目列表',
    推送升级: '推送升级',
    升级进度: '升级进度',
    '升级进度-详情': '升级进度-详情',
    版本统计: '版本统计',
    基础状态统计: '基础状态统计',
    '设备出库-删除': '设备出库-删除',
    '设备列表-关闭wif': '设备列表-关闭wifi',
    '设备列表-关闭定位': '设备列表-关闭定位',
    '设备列表-开启定位': '设备列表-开启定位',
    '设备列表-定时重启': '设备列表-定时重启',
    '设备列表-文件取回': '设备列表-文件取回',
    '设备列表-添加子分组': '设备列表-添加子分组',
    '设备列表-重命名子分组': '设备列表-重命名子分组',
    '设备列表-删除子分组': '设备列表-删除子分组',
    '设备列表-关闭虚拟按键': '设备列表-关闭虚拟按键',
    '设备列表-开启虚拟按键': '设备列表-开启虚拟按键',
    '设备列表-设置时区': '设备列表-设置时区',
    '设备列表-设置休眠': '设备列表-设置休眠',
    '设备列表-重启': '设备列表-重启',
    '设备列表-关机': '设备列表-关机',
    '设备列表-恢复出厂设置': '设备列表-恢复出厂设置',
    '分组管理-关闭wif': '分组管理-关闭wifi',
    '分组管理-关闭虚拟按键': '分组管理-关闭虚拟按键',
    '分组管理-开启虚拟按键': '分组管理-开启虚拟按键',
    '分组管理-设置时区': '分组管理-设置时区',
    '分组管理-设置休眠': '分组管理-设置休眠',
    '分组管理-重启': '分组管理-重启',
    '分组管理-关机': '分组管理-关机',
    '分组管理-恢复出厂设置': '分组管理-恢复出厂设置',
    '客户管理-关联客户': '客户管理-关联客户',
    自启动设置: '自启动设置',
    '设备列表-上传文件': '设备列表-上传文件',
    营销管理: '营销管理',
    素材管理: '素材管理',
    节目投放: '节目投放',
    '设备列表-屏幕亮度设置': '设备列表-屏幕亮度设置',
    '设备列表-字体大小设置': '设备列表-字体大小设置',
    外设配置: '外设配置',
    '设备列表-设备分组': '设备列表-设备分组',
    机构管理: '机构管理',
    '机构管理-新增': '机构管理-新增',
    '机构管理-编辑': '机构管理-编辑',
    '机构管理-删除': '机构管理-删除'
  },
  permissionName: {
    查询设备: '查询设备',
    应用列表查询: '应用列表查询',
    查看查询日志: '查看查询日志',
    查看设备详情: '查看设备详情',
    修改设备: '修改设备',
    删除设备: '删除设备',
    新增设备: '新增设备',
    新增设备型号: '新增设备型号',
    修改设备型号: '修改设备型号',
    删除设备型号: '删除设备型号',
    新增客户: '新增客户',
    修改客户: '修改客户',
    删除客户: '删除客户',
    新增客户账号: '新增客户账号',
    修改客户账号: '修改客户账号',
    删除客户账号: '删除客户账号',
    新增客户角色: '新增客户角色',
    修改客户角色: '修改客户角色',
    删除客户角色: '删除客户角色',
    新增资源: '新增资源',
    更新资源管理: '更新资源管理',
    删除资源管理: '删除资源管理',
    向设备发布命令: '向设备发布命令',
    新增分组: '新增分组',
    修改分组: '修改分组',
    删除分组: '删除分组',
    新增应用: '新增应用',
    修改应用: '修改应用',
    删除应用: '删除应用',
    应用详情: '应用详情',
    版本详情: '版本详情',
    版本下载: '版本下载',
    删除版本: '删除版本',
    新增账号: '新增账号',
    修改账号: '修改账号',
    删除账号: '删除账号',
    新增角色: '新增角色',
    修改角色: '修改角色',
    删除角色: '删除角色',
    查询操作日志: '查询操作日志',
    远程协助: '远程协助',
    设备分组: '设备分组'
  },
  ecxelHeader: {
    sn: 'SN(必填)',
    deviceType: '设备型号(必填)',
    spId: '服务商ID(必填)',
    printerId: '打印机ID',
    scanningEquipmentId: '扫码设备ID',
    cameraId: '摄像头ID',
    electronicScalesId: '电子秤ID',
    store: '门店'
  },
  ecxelHeader2: {
    head1: 'SN(必填)',
    head2: '设备型号',
    head3: '固件版本',
    head4: '服务商名称ID(必填)',
    head5: '分组名称(必填)',
    head6: '地址',
    head7: '国家',
    head8: '省份',
    head9: '城市',
    head10: '地区'
  },
  ecxelHeader3: {
    head1: 'SN(必填)',
    head2: '分组名称(必填)',
    head3: '国家',
    head4: '省份',
    head5: '城市',
    head6: '地区'
  }
}
